import * as React from 'react';
import LoadingButton from '../loading-button/loading-button.component';
import { LAYOUT_BG_COLOR } from 'shared/styles/colors';
import { SxProps, Box, Typography, Modal } from '@mui/material';

interface IBasicModal {
  btnText: string;
  children: React.ReactNode;
  headline: string;
}

export default function BasicModal({
  btnText,
  headline,
  children,
}: IBasicModal) {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleModal = () => setIsOpen(!isOpen);

  const style: SxProps = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: LAYOUT_BG_COLOR,
    boxShadow: 24,
    p: '32px',
    borderRadius: '12px',
    maxWidth: '525px',
    boxSizing: 'border-box',
  };

  return (
    <Box>
      <LoadingButton onClick={toggleModal}>{btnText}</LoadingButton>
      <Modal open={isOpen} onClose={toggleModal}>
        <Box sx={style}>
          <Typography variant='h2' fontSize={24} lineHeight={'32px'}>
            {headline}
          </Typography>
          {children}
        </Box>
      </Modal>
    </Box>
  );
}
